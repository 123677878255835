<template>
  <v-container class="pa-12">
    <v-row justify-md="center">
      <v-col cols="12" class="d-flex d-sm-none">
        <v-menu
          :close-on-content-click="false"
          offset-y
          v-model="responsiveSearchMenu"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-text-field
              v-on="on"
              v-bind="attrs"
              :label="$t('common.search')"
              append-icon="mdi-menu-down"
              readonly
            ></v-text-field>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="query.query.transactionId"
                    :placeholder="
                      $t('tables.headers.payment_transactions.transactionId')
                    "
                    clearable
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-menu
                    ref="show_start_date"
                    :close-on-content-click="false"
                    v-model="toggleDatePicker"
                    :return-value.sync="query.query.transactionDate"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="query.query.transactionDate"
                        readonly
                        v-on="on"
                        clearable
                      >
                        <template v-slot:prepend-inner>
                          <v-icon v-on="on">mdi-calendar</v-icon>
                        </template>
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="query.query.transactionDate"
                      no-title
                      @input="filterStartDate"
                    ></v-date-picker>
                  </v-menu>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-content>
                  <v-select
                    v-model="query.query.clientId"
                    placeholder="Kurum"
                    :items="clients"
                    item-text="text"
                    item-value="value"
                    clearable
                  />
                </v-list-item-content>
              </v-list-item> -->
              <!-- <v-list-item>
                <v-list-item-content>
                  <v-select
                    v-model="query.query.methodId"
                    placeholder="Metot"
                    :items="paymentMethods"
                    item-text="text"
                    item-value="value"
                    clearable
                  />
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="query.query.orderId"
                    clearable
                    :placeholder="
                      $t('tables.headers.payment_transactions.orderId')
                    "
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="query.query.customerId"
                    :placeholder="
                      $t('tables.headers.payment_transactions.customerId')
                    "
                    clearable
                  />
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item>
                <v-list-item-content>
                  <v-select
                    v-model="query.query.customerGroup"
                    placeholder="Müşteri Tipi"
                    :items="customerTypes"
                    item-text="text"
                    item-value="value"
                    clearable
                  />
                </v-list-item-content>
              </v-list-item> -->
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="query.query.customerEmail"
                    :placeholder="
                      $t('tables.headers.payment_transactions.customerEmail')
                    "
                    clearable
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-select
                    v-model="query.query.currencyCode"
                    :items="currencyOptions"
                    clearable
                    :placeholder="
                      $t('tables.headers.payment_transactions.currencyCode')
                    "
                  ></v-select>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-select
                    v-model="query.query.transactionStatus"
                    :placeholder="
                      $t(
                        'tables.headers.payment_transactions.transactionStatus'
                      )
                    "
                    :items="transactionResults"
                    item-text="text"
                    item-value="value"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn block color="primary" @click="responsiveMenuClick">
                {{ $t("common.search") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12">
        <v-card elevation="2" class="pa-6" rounded="lg">
          <v-container>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="tableHeader"
                  :items="tableItems"
                  :hide-default-footer="hideFooter"
                  :hide-default-header="$vuetify.breakpoint.smAndUp"
                  disable-sort
                  @dblclick:row="showDialogDblClick"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>
                        {{ $t("tables.titles.payment_transactions") }}
                      </v-toolbar-title>
                      <v-spacer />
                    </v-toolbar>
                  </template>
                  <template v-slot:header>
                    <thead class="v-data-table-header">
                      <tr>
                        <th
                          v-for="(item, i) in tableHeader"
                          :key="i"
                          role="columnheader"
                          scope="col"
                          colspan="1"
                        >
                          {{ item.text }}
                        </th>
                      </tr>
                      <tr>
                        <th role="columnheader" scope="col" colspan="1">
                          <v-text-field
                            v-model="query.query.transactionId"
                          ></v-text-field>
                        </th>
                        <th role="columnheader" scope="col" colspan="1">
                          <v-menu
                            ref="show_start_date"
                            :close-on-content-click="false"
                            v-model="toggleDatePicker"
                            :return-value.sync="query.query.transactionDate"
                            transition="scale-transition"
                            offset-y
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="query.query.transactionDate"
                                readonly
                                v-on="on"
                                clearable
                              >
                                <template v-slot:prepend-inner>
                                  <v-icon v-on="on">mdi-calendar</v-icon>
                                </template>
                              </v-text-field>
                            </template>

                            <v-date-picker
                              v-model="query.query.transactionDate"
                              no-title
                              @input="filterStartDate"
                            ></v-date-picker>
                          </v-menu>
                        </th>
                        <th role="columnheader" scope="col" colspan="1"></th>
                        <th role="columnheader" scope="col" colspan="1">
                          <v-text-field
                            v-model="query.query.orderId"
                          ></v-text-field>
                        </th>
                        <th role="columnheader" scope="col" colspan="1">
                          <v-text-field
                            v-model="query.query.customerId"
                          ></v-text-field>
                        </th>
                        <th role="columnheader" scope="col" colspan="1">
                          <v-text-field
                            v-model="query.query.customerEmail"
                          ></v-text-field>
                        </th>

                        <th role="columnheader" scope="col" colspan="1">
                          <v-select :items="currencyOptions"></v-select>
                        </th>
                        <th></th>
                        <th role="columnheader" scope="col" colspan="1">
                          <v-select
                            v-model="query.query.transactionStatus"
                            :items="transactionResults"
                          ></v-select>
                        </th>
                        <th></th>
                        <th role="columnheader" scope="col" colspan="1">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                icon
                                @click="getTransactionList"
                              >
                                <v-icon>mdi-magnify</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("common.search") }}</span>
                          </v-tooltip>
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:item.methodName="{ item }">
                    {{
                      `${item.methodName} ${$t(
                        "payment_categories." +
                          choose_payment_category(item.methodCategoryId)
                      )}`
                    }}
                  </template>
                  <template v-slot:item.transactionStatus="{ item }">
                    {{ $t(chooseCtx("text", item.transactionStatus)) }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          @click="showDialog('read', item)"
                        >
                          <v-icon color="secondary">
                            mdi-eye
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("common.details") }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <TransactionDetail :form="form" :dialogs="dialogs" />
  </v-container>
</template>

<script>
import requests from "@/mixins/requests";
import choose from "@/mixins/choose";
import TransactionDetail from "@/components/TransactionDetail.vue";
export default {
  name: "Home",
  metaInfo: function() {
    return {
      title: `${this.$t("pages.titles.payment_transactions")}`,
      titleTemplate: `%s | PAYMAPI ${this.$t("app")}`
    };
  },
  mixins: [requests, choose],
  components: { TransactionDetail },
  data: () => ({
    tableItems: [],
    form: {
      transactionId: null,
      transactionDate: null,
      methodName: null,
      methodCategoryId: null,
      orderId: null,
      customerId: null,
      customerEmail: null,
      orderTotal: null,
      currencyCode: null,
      transactionStatus: null,
      resultTxt: null
    },
    query: {
      page: 0,
      limit: 15,
      query: {
        transactionId: "",
        transactionDate: "",
        orderId: "",
        customerId: "",
        customerEmail: "",
        currencyCode: "",
        transactionStatus: ""
      }
    },
    options: {
      page: 1,
      itemsPerPage: 10
    },
    searchOptions: [
      { value: "", text: "Hepsi" },
      { value: "1", text: "Aktif" },
      { value: "0", text: "Pasif" }
    ],
    responsiveSearchMenu: false,
    toggleDatePicker: false,
    clients: [],
    paymentMethods: [],
    dialogs: {
      read: false
    },

    currencyOptions: [
      { value: "TRY", text: "TRY" },
      { value: "USD", text: "USD" },
      { value: "EUR", text: "EUR" }
    ]
  }),
  computed: {
    hideFooter() {
      return this.tableItems.length < 10;
    },
    tableHeader() {
      let headers = [];
      [
        "transactionId",
        "transactionDate",
        "methodName",
        "orderId",
        "customerId",
        "customerEmail",
        "currencyCode",
        "orderTotal",
        "transactionStatus",
        "resultTxt",
        "actions"
      ].map(item => {
        headers.push({
          value: item,
          text: this.$t(`tables.headers.payment_transactions.${item}`)
        });
      });
      return headers;
    },
    transactionResults() {
      return [
        { text: this.$t("status.waiting_for_approval"), value: "10" },
        { text: this.$t("status.all_transactions"), value: "" },
        { text: this.$t("status.success"), value: "1" },
        { text: this.$t("status.unsuccess"), value: "2" }
      ];
    }
  },
  async mounted() {
    await this.getTransactionList();
  },
  methods: {
    async getTransactionList() {
      const data = await this._transactionList(this.query);
      this.tableItems = data.data;
    },
    async responsiveMenuClick() {
      this.responsiveSearchMenu = false;
      await this.getTransactionList();
    },
    async readTransaction(id) {
      const data = await this._readTransaction(id);
      this.form = { ...data };
      console.log(this.form);
    },
    async showDialog(dialog, item = null) {
      item ? await this.readTransaction(item.transactionId) : null;
      this.dialogs[dialog] = true;
    },
    showDialogDblClick(body, { item }) {
      this.showDialog("read", item);
    },
    resetForm(dialog) {
      Object.keys(this.form).map(item => {
        this.form[item] = null;
      });
      this.dialogs[dialog] = false;
    },
    filterStartDate(val) {
      this.$refs.show_start_date.save(val);
    }
  }
};
</script>
