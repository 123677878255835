<template>
  <v-dialog
    v-model="dialogs.read"
    @input="$emit('reset', { dialog: 'read', form })"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        {{ $t("components.payment_transaction_read.transactionId") }}:
        {{ form.transactionId }}
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs fixed-tabs>
        <v-tab>
          {{ $t("components.payment_transaction_read.tabs.transaction") }}
        </v-tab>
        <v-tab>
          {{ $t("components.payment_transaction_read.tabs.customer") }}
        </v-tab>
        <v-tab-item>
          <v-container class="modal-row">
            <div class="d-flex flex-column flex-md-row">
              <!-- <v-card-text class="d-flex flex-column">
                <b>Kurum:</b>
                {{ form.clientName }}
              </v-card-text> -->
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.paymentMethodName")
                  }}:</b
                >
                {{
                  `${form.paymentMethodName} ${$t(
                    "payment_categories." +
                      choose_payment_category(form.paymentMethodCatId)
                  )}`
                }}
              </v-card-text>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.currencyCode")
                  }}:</b
                >
                {{ form.currencyCode }}
              </v-card-text>
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.orderTotal")
                  }}:</b
                >
                {{ form.orderTotal }}
              </v-card-text>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b>{{ $t("components.payment_transaction_read.orderId") }}:</b>
                {{ form.orderId }}
              </v-card-text>

              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.transactionStatus")
                  }}:</b
                >
                <div class="d-flex align-center">
                  <span>
                    {{ $t(chooseCtx("text", form.transactionStatus)) }}
                  </span>
                  <template v-if="form.transactionStatus === '10'">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="primary"
                          @click="$emit('approve', true, true)"
                        >
                          <v-icon>mdi-check-bold</v-icon>
                        </v-btn>
                      </template>
                      <span>Onayla</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          color="secondary"
                          @click="$emit('approve', false, true)"
                        >
                          <v-icon>mdi-close-thick</v-icon>
                        </v-btn>
                      </template>
                      <span>Reddet</span>
                    </v-tooltip>
                  </template>
                </div>
              </v-card-text>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.resultCode")
                  }}:</b
                >
                {{ $t(`status.${form.resultCode}`) }}
              </v-card-text>
              <v-card-text class="d-flex flex-column">
                <b
                  >{{ $t("components.payment_transaction_read.resultTxt") }}:</b
                >
                {{ form.resultTxt }}
              </v-card-text>
            </div>
            <!-- <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b>Ödeme Referansı:</b>
                {{ form.paymentRef }}
              </v-card-text>
              <v-card-text class="d-flex flex-column">
                <b>Ödeme Onay Kodu:</b>
                {{ form.authRef }}
              </v-card-text>
            </div> -->
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b>{{ $t("components.payment_transaction_read.initTime") }}:</b>
                {{ form.initTime }}
              </v-card-text>
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.resultTime")
                  }}:</b
                >
                {{ form.resultTime }}
              </v-card-text>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.callbackURL")
                  }}:</b
                >
                {{ form.callbackURL }}
              </v-card-text>
            </div>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container class="modal-row">
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.customerId")
                  }}:</b
                >
                {{ form.customerId }}
              </v-card-text>
              <v-card-text class="d-flex flex-column">
                <b
                  >{{ $t("components.payment_transaction_read.customerName") }}
                  {{
                    $t("components.payment_transaction_read.customerSurname")
                  }}:</b
                >
                {{ form.customerName }} {{ form.customerSurname }}
              </v-card-text>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <!-- <v-card-text class="d-flex flex-column">
                <b>Grup:</b>
                {{ form.customerGroup }}
              </v-card-text> -->
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.customerEmail")
                  }}:</b
                >
                {{ form.customerEmail }}
              </v-card-text>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.customerSsn")
                  }}:</b
                >
                {{ form.customerSsn }}
              </v-card-text>
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.customerTelephone")
                  }}:</b
                >
                {{ form.customerTelephone }}
              </v-card-text>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.customerCountry")
                  }}:</b
                >
                {{ form.customerCountry }}
              </v-card-text>
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.customerCity")
                  }}:</b
                >
                {{ form.customerCity }}
              </v-card-text>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.customerZipCode")
                  }}:</b
                >
                {{ form.customerZipCode }}
              </v-card-text>
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.customerAddress")
                  }}:</b
                >
                {{ form.customerAddress }}
              </v-card-text>
            </div>
            <div class="d-flex flex-column flex-md-row">
              <v-card-text class="d-flex flex-column">
                <b
                  >{{
                    $t("components.payment_transaction_read.customerIP")
                  }}:</b
                >
                {{ form.customerIP }}
              </v-card-text>
            </div>
          </v-container>
        </v-tab-item>
      </v-tabs>
      <v-divider />
    </v-card>
  </v-dialog>
</template>

<script>
import chooses from "@/mixins/choose";
export default {
  name: "PaymentTransactionListRead",
  mixins: [chooses],
  props: ["form", "dialogs"],
};
</script>
